import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/Footer";
// import Feature from "../components/Feature";

import CookieConsent from "../components/CookieConsent";
// import { features } from "../content/features2";
import BigPic from "../components/BigPic/BigPicTwo";
import SalesIQ from "../components/SalesIQ";
import Results from "../components/Results";
import BenefitsTab from "../components/BenefitsTab";
import VideoExplaination from "../components/Sections/VideoExplaination";
import KeySolutions from "../components/Sections/KeySolutions";
import PartnerSoftwares from "../components/Sections/PartnerSoftwares";
//import StudentOutcome from "../components/Sections/StudentOutcome";
import StayInTouch from "../components/Sections/StayInTouch";
//import OurEar from "../components/Sections/OurEar";

function IndexPage() {
  function handleCalendlyClick() {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/documentdaily/document-daily-demo",
    });
  }

  return (
    <main className={"bg-babyBlue"}>
      <Helmet>
        <title>EDclerk: Simple, Effective, Practical</title>
        <meta
          name="keywords"
          content="HB, 4545, restroom, easy, passes, teachers, teacher, flex, period, hallway,
          monitoring, PBIS, SEL, organize, positive, support, easy, teachers, special, education,
          504, documentation, compliance, plate, events"
        />
        <meta
          name="description"
          content="Helping schools prevent compliance issues,
          collecting compliance documentation, and keep track of student behavior
          with cloud based software that notify teachers and mentors in a
          collaborative interface"
        />
        <meta name="icon" href="../assets/favicon.ico" />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        ></script>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
      </Helmet>
      <BigPic />
      <Results />
      <BenefitsTab />
      <VideoExplaination />
      {/* <section className="my-16 container mx-auto">
        <h3 className={"section-heading text-center my-4"}>
          Logistics, Mentoring, and Compliance–All in One Place
        </h3>
        <div className="grid ">
          <dl className="grid  md:grid-cols-3  gap-5 mb-5 mx-10 xs:mx-10 md:mx-2 lg:mx-8 xl:mx-40">
            {features.map(({ icon, icon1, icon2, icon3, title }) => (
              <Feature
                className="bg-white"
                key={title}
                icon={icon}
                icon1={icon1}
                icon2={icon2}
                icon3={icon3}
                title={title}
              />
            ))}
          </dl>
        </div>
      </section> */}
      <KeySolutions />
      {/* <OurEar /> */}
      <PartnerSoftwares />
      {/* <StudentOutcome /> */}
      <StayInTouch />
      <CookieConsent />
      <SalesIQ />
      <Footer />
    </main>
  );
}

export default IndexPage;
