import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import React from "react";

export function ScheduleFreeDemoButton({ className, hideIcon }) {
  function handleCalendlyClick() {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/edclerk/edclerk-demo",
    });
  }

  return (
    <div
      onClick={handleCalendlyClick}
      className={`flex gap-x-1 items-center bg-yellow-clerk text-navy-default px-2 py-5 lg:text-2xl sm:text-xl text-lg font-medium rounded-lg cursor-pointer justify-center ${className}`}
    >
      Find Out How
      {hideIcon ? null : <BsArrowRight className={"mt-1"} />}
    </div>
  );
}

export function CallToAction() {
  function handleCalendlyClick() {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/edclerk/edclerk-demo",
    });
  }

  return (
    <div className="text-center ">
      <h3 className="text-2xl lg:text-3xl font-bold ">
        Simple, Effective and Practical.
      </h3>
      <p className="text-xl lg:text-2xl">Schedule a demo today!</p>
      <button
        className="btn font-medium mt-7 rounded-md mb-4"
        onClick={handleCalendlyClick}
      >
        Schedule demo
      </button>
    </div>
  );
}

export function CallToAction2() {
  function handleCalendlyClick() {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/edclerk/edclerk-demo",
    });
  }

  return (
    <div className="text-center mt-10">
      <h2 className="text-2xl lg:text-3xl">Schedule a demo today!</h2>
      <button
        className="btn font-medium mt-7 rounded-md mb-4"
        onClick={handleCalendlyClick}
      >
        Schedule demo
      </button>
    </div>
  );
}

export function SendUserEmail() {
  function handleFormClick() {
    return (
      <form className={"flex items-center"}>
        {/* <input
        className={
          "py-3 focus:outline-none border-gray bg-babyBlue w-full px-2"
        }
        type="email"
        placeholder={"Enter Email"}
        name="email"
        id="email"
      /> */}
        {/* <button className={"btn"}>Subscribe</button> */}
        <button
          className="btn font-medium mt-7 rounded-md mb-4"
          onClick={handleFormClick}
        >
          {" "}
          Schedule demo{" "}
        </button>
      </form>
    );
  }
}
