import React, { useState } from "react";
import { ScheduleFreeDemoButton } from "./CallToAction";

export default function BenefitsTab() {
  const [currentTab, setCurrentTab] = useState(0);

  const tabsData = [
    {
      title: "Hallways",
      data: [
        "BETTER HALL MANAGENT: EDclerk helps manage hall traffic, making students less late and improving attendance.",
        "DIGITAL HALL PASSES: The system gives out digital hall passes. This helps keep students where they should be and reduces unwanted hallway activity.",
        "LOCATION TRACKING: EDclerk can show where students are in real-time by working with other security systems. This helps staff keep an eye on hall traffic and fix issues early.",
        "REPORTING SYSTEM: EDclerk can make reports on how hall traffic is doing. This helps school leaders spot problems and make the school safer and run more smoothly.",
      ],
    },
    {
      title: "Behavior",
      data: [
        "WATCHING BEHAVIIOR: EDclerk gives updates on how students act right away, helping stop problems quickly and making classes nicer for all.",
        "TRACK BEHAVIOR YOUR WAY: Schools can set up EDclerk to track behavior in a way that suits them, like watching good and bad actions or using a points system for rewards.",
        "UPDATES FOR PARENTS: EDclerk can send behavior news to parents right away. This helps schools and parents team up to fix any issues.",
        "USING DATA TO DECIDE: EDclerk uses information to see patterns in behavior. This helps schools know the best ways to help students.",
      ],
    },

    {
      title: "Services",
      data: [
        "PERSONALIZED SCHEDULING: With EDclerk, you can set up things like counseling, extra support, tutorials, group meetings, and sports dismissals all in one place.",
        "IEP MANAGEMENT: EDclerk makes it easier to handle Individualized Education Plans. It helps you create, update, and keep track of them.",
        "TRACKING PROGRESS: EDclerk helps track how well students with special needs are doing. This gives teachers and administrators information to help make choices about support and adjustments.",
        "USING DATA TO DECIDE: EDclerk uses data to see how students behave. This helps schools figure out the best ways to support them.",
      ],
    },
    {
      title: "Workload",
      data: [
        "AUTOMATED TASKS: EDclerk does routine data collection, monitoring, scheduling, and notfications to keep teachers and staff in the know. This gives teachers more time to teach and plan.",
        "ALL-IN-ONE DASHBOARD: EDclerk's dashboard lets you manage school tasks easily in one place. It helps keep things tidy and efficient.",
        "EASY COMMUNICATION: EDclerk includes messaging tools. This makes it simple for teachers to chat with students, parents, and each other.",
      ],
    },
  ];
  return (
    <section className={" lg:py-0 container mx-auto"}>
      <div className={"flex lg:flex-row flex-col items-center"}>
        <div className="sm:p-8 p-4 lg:w-3/5 md:w-4/5 w-full lg:order-1 order-2">
          <ul className="grid grid-flow-col text-center text-gray-500">
            {tabsData.map((tab, index) => (
              <li
                onClick={() => {
                  setCurrentTab(index);
                }}
                className={`cursor-pointer md:text-xl text-base font-bold text-white flex justify-center py-2 px-1 bg-blue-secondary rounded-t-lg ${
                  currentTab === index ? "!bg-blue-medium" : ""
                }`}
              >
                {tab.title}
              </li>
            ))}
          </ul>
          <div className="bg-blue-medium shadow py-2 text-white rounded-b-lg -mt-2">
            <ul className="text-white section-paragraph">
              {tabsData[currentTab]?.data.map((item) => (
                <li
                  className={
                    "text-white even:!bg-blue-secondary my-1 p-2 px-4 flex items-center gap-x-2"
                  }
                >
                  {/* <div className={"w-2 h-2 rounded-full bg-white"} /> */}
                  {item}
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="perspective-card">
            <div className="benefits-card-perspective md:text-2xl text-lg text-navy-default shadow-lg shadow-slate-200 py-4 px-2 text-center lg:w-[45%] w-[48%] bg-white font-bold rounded-lg">
              Works for Every Educator
            </div>
          </div> */}
        </div>
        <div
          className={
            "lg:w-2/5 sm:w-4/5 w-[90%] px-4 italic md:text-xl text-base text-navy-default font-medium lg:order-2 order-1"
          }
        >
          <p className={"leading-6 text-2xl"}>
            Streamline and simplify tasks to proactively manage students.
            <br />
            <br />
            Easy to use and affordable, EDclerk is the only solution that does
            it of it in one place.
            <br />
            <br />
            Contact EDclerk today!
          </p>
          {/* <p className={"mt-4"}>- Alison Shepperd, Math Interventionist</p> */}
        </div>
      </div>
      <ScheduleFreeDemoButton className={"justify-center w-4/5 mx-auto"} />
    </section>
  );
}
