import React from "react";
import { useEffect } from "react";

//Zoho Sales Iq Script:
const useScript = (url, widgetCode) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "f70d2d1a8432c4c9cf00ec91255b24f7604921e215f144251fec487202d8d0372ab617113f030c68515ef332378d03ac", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function SalesIQ() {
  return (
    <React.Fragment>
      {useScript(
        "https://salesiq.zoho.com/widget",
        "26e0341d5fd3387e156094eb3ca4f337342596e67878f57aed5ce5be0be61aa4"
      )}
    </React.Fragment>
  );
}
