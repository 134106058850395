import React from "react";

export default function Results() {
  return (
    <section className={"bg-white py-4 px-2 my-12 lg:my-6 w-full shadow-lg"}>
      <div className="container mx-auto">
        <h2 className="section-heading text-center mb-3">Get Started Now!</h2>
        <div
          className={
            "grid sm:grid-cols-2 grid-cols-1 mx-auto section-secondary-paragraph justify-center w-full"
          }
        >
          {/* <p
            className={
              "md:text-left md:justify-self-end xl:w-[42%] lg:w-[55%] sm:w-[65%] mb-4 px-3"
            }
          >
            Schools that use EDclerk&copy; experience immediate benefits.
            <br />
            Ask about our free trial
          </p> */}{" "}
          <ul className={"px-6 text-xl mx-auto list-disc"}>
            <li>AI-powered staff assistant</li>
            <li>Mentor student behavior better</li>
            <li>Reduce tardies and overall Hall Traffic</li>
          </ul>
          <ul className={"px-6 text-xl mx-auto list-disc"}>
            <li>Easy data collection, stay in compliance</li>
            <li>Schedule and track student services</li>
            <li>Sporting/Activity event dismisal</li>
          </ul>
        </div>
      </div>
    </section>
  );
}
