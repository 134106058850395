import React from "react";
import Logo from "../../assets/edclerkLogo2.svg";
// import { SendUserEmail } from "../CallToAction";
// import { ScheduleFreeDemoButton } from "../CallToAction";

export default function VideoExplaination() {
  return (
    <section className={"my-8 lg:my-8 w-full bg-white px-10 py-[5%]"}>
      <div className="container mx-auto">
        <div
          className={
            "flex md:flex-row flex-col items-center justify-around w-full"
          }
        >
          <div
            className={
              "md:w-1/2 sm:w-4/5 w-full flex flex-col justify-center items-center md:order-1 order-2"
            }
          >
            <Logo className={"rs:w-[450px] lg:w-[600px]"} />
            <h4 className={"section-heading flex justify-center"}>
              The singular application that consolidates the functionalities of
              five apps, streamlining your workflow, saving your funds, and
              simplifying the educational process for teachers and staff.
            </h4>
            {/* <p className={"section-paragraph mt-2 mb-6 "}>Contact US</p> */}
            {/* <SendUserEmail /> */}
            {/* <ScheduleFreeDemoButton
              className={"!bg-blue-medium !text-white !py-5 mt-4 md:mt-0 "}
            /> */}
            {/* <SendUserEmail /> */}
          </div>
          <div
            className={
              "xl:w-2/5 md:w-1/2 sm:w-4/5 w-full md:order-2 order-1 justify-self-center mx-auto"
            }
          >
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/aWV0sCEx9cQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </section>
  );
}
