import React from "react";
//import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import { ScheduleFreeDemoButton } from "../CallToAction";

export default function HeroThree() {
  return (
    <div
      className={
        "perspective-card rs:pt-8 xs:pt-8 xl:w-[35%] lg:w-[40%] w-4/5 mx-auto lg:order-1 order-2"
      }
    >
      <div className={"bg-white p-8 perspective-card-inner rounded-lg pb-20"}>
        <h3 className={"section-heading"}>
          Hallways, Behavior, Flex Time and more all for one low price.
        </h3>
        <p className={"section-paragraph"}>
          EDclerk is the ounce of prevetion to save your staff a ton of cure....
        </p>
      </div>
      <ScheduleFreeDemoButton
        className={
          "lg:translate-x-[50%] lg:-translate-y-14 lg:w-[70%] -translate-y-[70%]"
        }
      />
    </div>
  );
}
