import React from "react";
import Logo from "../../assets/edclerkLogo2.svg";
//import {ScheduleFreeDemoButton} from "../CallToAction";
// import { SendUserEmail } from "../CallToAction";

export default function StayInTouch() {
  return (
    <section className={"bg-white pb-6"}>
      <div className="container mx-auto">
        {/* <h3 className={"section-heading text-center"}>You Have Our Ear!</h3>
        <p className={"section-paragraph text-center w-[75%] mx-auto pt-4"}>
          The educational dynamic is always changing, and as you adapt, so do
          we. We welcome feedback and suggestions from Teachers and Admins
          alike. Many of our best features started as suggestions from users
          like you.
        </p> */}

        <div
          className={
            "flex md:flex-row flex-col md:items-end md:justify-around items-center justify-center md:w-[90%] w-full" +
            " md:mx-auto" +
            " my-12"
          }
        >
          <div className={"xl:w-[45%] w-full"}>
            <Logo className="h-[200px] w-[80%] md:ml-10 -mb-5 mx-auto" />
            <h3 className={"section-heading text-center"}>
              Simple. Effective. Practical.
            </h3>
          </div>
          {/* <ScheduleFreeDemoButton
              className={
                "!bg-blue-medium !text-white !py-5 mt-4 md:mt-0 xl:w-[25%]  md:w-[50%] w-4/5"
              }
            /> */}
          {/* <div>
            {/* <SendUserEmail /> */}
          {/* <h6>Join our mailing list</h6> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}
