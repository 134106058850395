import Clever from "../../assets/clever_logo.svg";
import ClassLink from "../../assets/ClassLink-Logo.svg";
import GoogleLogo from "../../assets/Google-Logo.svg";
import MicrosoftLogo from "../../assets/Microsoft-Logo.svg";
import React from "react";

export default function PartnerSoftwares() {
  return (
    <section className={"my-32 lg:my-12 container mx-auto"}>
      <h3 className={"section-heading text-center"}>
        Import Your Current Data With Ease
      </h3>
      <p
        className={
          "section-paragraph text-center lg:w-1/2 sm:w-3/4 mx-auto font-medium mb-10 mt-2"
        }
      >
        We are partnered with worldwide software companies to make your
        transition to EDclerk as smooth as possible.
      </p>

      <div className="grid sm:grid-cols-2 grid-cols-1 justify-items-center items-center lg:w-1/3 sm:w-3/5 mx-auto">
        <GoogleLogo className="-mt-4" />
        <MicrosoftLogo className="-mt-4 -ml-3" />
        <Clever />
        <ClassLink className="ml-3 sm:mt-1 mt-6" />
      </div>
    </section>
  );
}
