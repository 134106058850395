import React, { useLayoutEffect, useState } from "react";
import { Link } from "gatsby";

function CookieConsent() {
  const [isVisible, setVisible] = useState(true);

  useLayoutEffect(() => {
    const item = localStorage.getItem("cookieConsent");

    if (item) {
      setVisible(false);
    }
  }, []);

  function handleOkCookieConsent() {
    setVisible(false);
    localStorage.setItem("cookieConsent", "true");
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div className="w-full fixed bottom-0 left-0 right-0 bg-white text-center border-t p-4 border-gray">
      <span className="mr-3">
        This website uses cookies to ensure you get the best experience on our
        website.
      </span>
      <button
        className="mr-3 px-2 py-1 font-medium rounded text-xs border-2 border-gray"
        onClick={handleOkCookieConsent}
      >
        OK
      </button>
      <Link to="/privacy-policy">Learn More</Link>
    </div>
  );
}

export default CookieConsent;
