import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ScheduleFreeDemoButton } from "../CallToAction";

export default function KeySolutions() {
  const solutions = [
    {
      title: "Step 1: Optimize Hallways",
      paragraph: [
        "Track students: EDclerk helps watch where students go, like the bathroom, other classrooms, or the office. With digital hall passes and tracking, teachers can keep a better eye on the hallways.",
        "Improved safety: With EDclerk, it's easier to prevent problems in the hallways, help students behave, and make the school a safer, smoother place.",
      ],
    },
    {
      title: "Step 2: Manage Student Behavior",
      paragraph: [
        "AI and behavior: EDclerk uses AI to watch student behavior in real time. It lets teachers send custom alerts to staff. Also, it puts all the day's good behaviors or issues into one email for parents.",
        "This streamlined communication process fosters collaboration between school and home, promoting a positive learning environment and enabling proactive intervention and support strategies to address behavioral concerns effectively.",
      ],
    },
    {
      title: "Step 3: Ensure Data Compliance",
      paragraph: [
        "EDclerk simplifies the process of organizing and documenting progress monitoring, as well as managing accommodations for special education, Section 504, and Limited English Proficiency (LEP) students. The platform's user-friendly interface and smart features make it easy for teachers to track student progress, update individualized plans, and maintain compliance with federal and state regulations. By streamlining these essential tasks, EDclerk helps educators focus on delivering personalized instruction and support to students with unique needs, while reducing the administrative burden.",
      ],
    },
    {
      title: "Step 4: Flex Time and Event Scheduling",
      paragraph: [
        " EDclerk's intuitive scheduling system makes it incredibly simple to plan and manage repeating events such as inclusion minutes, pullout testing, counseling services, flex-time, COVID recovery protocols, athletic events, and school event dismissals. The platform's flexibility ensures seamless coordination with your PEIMS person, facilitating efficient scheduling and communication across the school community. By automating and centralizing these essential scheduling tasks, EDclerk allows teachers and administrators to focus on providing high-quality education and support services while reducing the time and effort spent on managing complex scheduling requirements.",
      ],
    },
  ];

  return (
    <section className="bg-blue-medium xl:px-20 md:px-10 px-8">
      <div className="container mx-auto">
        <div className={"flex flex-col lg:flex-row gap-x-8 justify-between"}>
          {/* <div
            className={
              "grid lg:order-1 order-2 sm:grid-cols-2 grid-cols-1 gap-x-8 gap-y-14 my-6"
            }
          >
            {solutions.map((solution) => (
              <div
                className={"text-navy-default text-center bg-white rounded-lg"}
              >
                <h3 className={"text-xl font-bold py-6 px-6 rounded-lg"}>
                  {solution.title}
                </h3>
                <div className={"py-4 px-4 bg-[#DCEAF3] h-[85%] rounded-lg"}>
                  {solution.paragraph.map((description) => (
                    <p className={"text-normal font-normal "}>{description}</p>
                  ))}
                </div>
              </div>
            ))}
          </div> */}
          <div className={"flex  order-1 w-full sm:justify-center mt-4 "}>
            <div className="perspective-card text-white">
              <StaticImage
                quality={100}
                src={"../../assets/hallway_mobile.jpg"}
                alt={"mobile phone in hand"}
                imgClassName={"!contain"}
                className={" shadow-3xl flex rounded-2xl "}
              />
              <div className={"py-8"}>
                <h2
                  className={
                    "font-bold flex sm:justify-center md:text-5xl sm:text-3xl text-2xl"
                  }
                >
                  EDclerk was a breeze to get started. We saw benefits
                  immediately!"
                </h2>

                <p className={"text-end md:text-xl text-base font-medium mt-4"}>
                  - Randall Buck, Waxahachie HS TX 6A
                </p>
              </div>
              <ScheduleFreeDemoButton className={"w-[90%] mx-auto  mb-6"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
