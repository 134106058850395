import { Link } from "gatsby";
import Logo from "../../assets/edclerkLogo2.svg";
import React from "react";

export default function HeaderThree() {
  function handleSignIn() {
    window.open(`https://app.${window.location.hostname}/sign-in`);
  }

  return (
    <nav className="relative bg-white border-gray-200 px-2 sm:px-4 rounded dark:bg-gray-800">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/">
          <Logo className="flex justify-start items-center w-[50%]  sm:w-[45%] h-3/6 lg:w-4/6" />
        </Link>
        <div
          onClick={handleSignIn}
          className="absolute right-0 top-0 bottom-0 bg-blue-clerk flex items-center cursor-pointer"
        >
          <a className="transition-all flex duration-500 font-bold text-white px-4">
            Login
          </a>
        </div>
      </div>
    </nav>
  );
}
