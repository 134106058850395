import Hero from "../Hero/HeroThree";
import React from "react";
import Header from "../Header/HeaderThree";
import { StaticImage } from "gatsby-plugin-image";

export default function BigPicTwo() {
  return (
    <section className="bg-blue-medium">
      <Header />
      <div
        className={
          "container mx-auto lg:h-[550px] flex flex-col lg:flex-row items-center justify-between"
        }
      >
        <Hero />
        <div
          className={
            "flex flex-col sm:flex-row items-end justify-center lg:block xl:w-[55%] lg:w-[50%] lg:order-2" +
            " hidden" +
            " top-[15%]" +
            " order-1" +
            " w-full" +
            " lg:relative py-10 px-8 md:py-5 lg:px-0 rs:pt-2 rs:pb-0"
          }
        >
          <StaticImage
            quality={100}
            src={"../../assets/hero-background.jpg"}
            alt={"Student report showcase"}
            imgClassName={"w-full flex  !object-fill !rounded-lg "}
            className={
              "2xl:w-[750px] lg:w-[450px] xl:w-[600px] rs:w-2/5 lg:!absolute lg:-bottom-[50px]" +
              " rounded-lg pd-8"
            }
          />
          <StaticImage
            quality={100}
            src={"../../assets/teacherInHall.jpg"}
            alt={"Event calendar showcase"}
            className={
              "xl:w-[350px] lg:w-[250px] rs:w-1/5 lg:right-5" +
              " lg:!absolute xl:right-20" +
              " 2xl:-top-60 lg:-top-32 xl:-top-40 drop-shadow-2xl border border-gray-light rounded-lg -mt-10 sm:-ml-14 sm:-mb-6 lg:mb-0 lg:ml-0"
            }
          />
        </div>
      </div>
    </section>
  );
}
